import React, { useState } from 'react';
import '../styles.scss';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Image from '../../gatsby-image';
import BrandQuote from '../../../images/testimonials/brandQuote.svg';
import PatronQuote from '../../../images/testimonials/patronQuote.svg';
import close from '../../../images/testimonials/close1.png';
import PlayIcon from '../../../images/testimonials/playNew.svg';
import PathPatronSvg from '../../../images/testimonials/Path_patron.svg';
import PathBrandSvg from '../../../images/testimonials/Path_brand.svg';

const TestimonialCardWeb = (props) => {
  const { name, head, dp, profile, url, isBrand, isHomepage } = props;

  const [modal, setModal] = useState(false);

  const onOpenModal = (e) => {
    e.stopPropagation();
    setModal(true);
  };

  const onClose = () => {
    setModal(false);
  };

  return (
    <div className="testimonial-card-container">
      <figure
        className={`web  ${isBrand ? 'brand' : 'patron'} ${
          isHomepage ? 'homepage' : ''
        }`}
      >
        <div className="image-container">
          <div className="imgae">
            <div className="path-container">
              <img src={isBrand ? PathBrandSvg : PathPatronSvg} alt="background path" />
            </div>
            <Image filename={dp} alt="profile-sample3" className="profile" />
          </div>
        </div>
        <div className="card-body">
          <div className="text-container">
            <div className="type">
              <img alt="quote" src={isBrand ? BrandQuote : PatronQuote} />
              <p>{isBrand ? 'KLUB BRAND' : 'KLUB PATRON'}</p>
            </div>
            <blockquote>{head}</blockquote>
          </div>
          <div
            className={`actions-container ${isHomepage ? 'd-none' : 'd-flex'}`}
          >
            <div className="info">
              <h4>{name}</h4>
              <small>{profile}</small>
            </div>
            <div className="action">
              <button className="button" type="button" onClick={onOpenModal}>
                Watch the Video
                {' '}
                <img alt="play" src={PlayIcon} />
              </button>
            </div>
          </div>
          <div
            className={`actions-container ${isHomepage ? 'd-flex' : 'd-none'} ${props.customMargin ? 'm-cus' : ''}`}
          >
            <div className="profile">
              <div className="imgae">
                <div className="path-container">
                  <img src={isBrand ? PathBrandSvg : PathPatronSvg} alt="background path" />
                </div>
                <Image
                  filename={dp}
                  alt="profile-sample3"
                  className="profile"
                />
              </div>
            </div>
            <div className="actions">
              <div className="info">
                <h4>{name}</h4>
                <small>{profile}</small>
              </div>
              <div className="action">
                {url ? (
                  <button
                    className="button"
                    type="button"
                    onClick={onOpenModal}
                  >
                    Watch the Video
                    {' '}
                    <img alt="play" src={PlayIcon} />
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </figure>
      <Modal open={modal} center={true} onClose={onClose}>
        <div>
          <div className="closeBtn" onClick={onClose} onKeyDown={onClose}>
            <img src={close} alt="close" />
          </div>
          <iframe
            title="video"
            width="100%"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="true"
            height="500px"
          />
        </div>
      </Modal>
    </div>
  );
};

export default TestimonialCardWeb;
