import React from 'react';
import './styles.scss';
import TestimonialCardMobile from './mobileCard';
import TestimonialCardWeb from './webViewCard';

const TestimonialCard = (props) => (
  <div className="testimonial-main-container">
    <div className="d-none d-md-block">
      <TestimonialCardWeb {...props} />
    </div>
    <div className="d-block d-md-none">
      <TestimonialCardMobile {...props} />
    </div>
  </div>
);

export default TestimonialCard;
