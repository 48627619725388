import React, { useState } from 'react';
import '../styles.scss';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Image from '../../gatsby-image';
import PlayIcon from '../../../images/testimonials/playNew.svg';
import BrandQuote from '../../../images/testimonials/brandQuote.svg';
import PatronQuote from '../../../images/testimonials/patronQuote.svg';
import close from '../../../images/testimonials/close1.png';

const TestimonialCardMobile = (props) => {
  const { name, head, dp, profile, url, isBrand } = props;

  const [modal, setModal] = useState(false);

  const onOpenModal = () => {
    setModal(true);
  };

  const onClose = () => {
    setModal(false);
  };
  return (
    <div className="testimonial-card-container">
      <figure className={`mobile  ${isBrand ? 'brand' : 'patron'}`}>
        <Image filename={dp} alt={name} className="profile" />
        <figcaption>
          <h4>
            {name}
          </h4>
          <small>{profile}</small>
          <div className="type">
            <img alt="quote" src={isBrand ? BrandQuote : PatronQuote} />
            <p>{ isBrand ? 'KLUB BRAND' : 'KLUB PATRON' }</p>
          </div>
          <blockquote>{head}</blockquote>
          {
            url ? (
              <button className="button" type="button" onClick={onOpenModal}>
                Watch the Video
                {' '}
                <img alt="play" src={PlayIcon} />
              </button>
            ) : null
          }
        </figcaption>
      </figure>
      <Modal open={modal} center={true} onClose={onClose}>
        <div>
          <div className="closeBtn" onClick={onClose} onKeyDown={onClose}>
            <img src={close} alt="close" />
          </div>
          <iframe
            title="video"
            width="100%"
            src={url}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="true"
            height="500px"
          />
        </div>
      </Modal>
    </div>
  );
};

export default TestimonialCardMobile;
