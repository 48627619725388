/* eslint-disable import/prefer-default-export */

export const videoList = {
  home: [
    {
      id: '1',
      imgSrc: 'testimonials/the-new-shop.png',
      head:
        `The thing that excites us the most about Klub is that they created a customized financing option that would work best to finance our capex and inventory spends.`,
      name: 'Aastha Almast',
      profile: 'Co-founder, The New Shop',
      customMargin: false,
      // url:
      //   'https://www.youtube-nocookie.com/embed/Ehu14HAzQ-E?showinfo=0&rel=0&autoplay=1&mute=0',
      dp: 'testimonials/aastha-almast.png',
      isBrand: true
    },
    {
      id: '2',
      imgSrc: 'testimonials/tjori.png',
      head:
        `As founders, we really value our equity. Klub lets us raise revenue-linked capital without dilution and also helps eliminate the challenge of committed cash flow with traditional debt.`,
      name: 'Mansi Gupta & Ankit Wadhwa',
      profile: 'Co-founders, Tjori',
      // url:
      //   'https://www.youtube-nocookie.com/embed/IfTb0Yz8tQE?showinfo=0&rel=0&autoplay=1&mute=0',
      dp: 'testimonials/mansi-gupta.png',
      isBrand: true
    },
    {
      id: '4',
      imgSrc: 'testimonials/tejasvi-video.png',
      head:
        `As an investment that provides good returns with my risk on capital reducing every month, I found Klub's RBF model to be very interesting.`,
      name: 'Tejasvi Bhat',
      profile: 'Director, Ce-Chem Pharmaceuticals Pvt. Ltd.',
      // url:
      //   'https://www.youtube-nocookie.com/embed/4Ogr3BKBmmA?showinfo=0&rel=0&autoplay=1&mute=0',
      dp: 'testimonials/tejasvi-bhat.png',
      isBrand: false
    },
    {
      id: '5',
      imgSrc: 'testimonials/sameep-video.png',
      head: `Klub's approach to providing debt-funding to startups allows individual investors to diversify their portfolio through a selection of D2C companies to choose from.`,
      name: 'Sameep Kasbekar',
      profile: 'Research Analyst, Greenfield Advisory',
      // url:
      //   'https://www.youtube-nocookie.com/embed/KlUsHgWDzKE?showinfo=0&rel=0&autoplay=1&mute=0',
      dp: 'testimonials/sameep-kasbekar.png',
      isBrand: false
    },
    {
      id: '6',
      imgSrc: 'testimonials/Ankur-Poddar.png',
      head:
        'Klub’s platform provides a new-age investment opportunity, which gives access to loved brands, previously only available to HNI’s & Institutional Investors.',
      name: 'Ankur Poddar',
      profile: 'Head of New Business Development, Mahindra',
      url: null,
      dp: 'testimonials/Ankur-Poddar.png',
      isBrand: false
    },
    {
      id: '7',
      imgSrc: 'testimonials/Anish-Jain.png',
      head:
        'Klub is giving the opportunity to invest in new brands in a simple and secure manner. The team at Klub is proactive, and the platform is already flowing back returns on schedule!',
      name: 'Anish Jain',
      profile: 'Head of Sales, Google',
      url: null,
      dp: 'testimonials/Anish-Jain.png',
      isBrand: false
    },
    {
      id: '8',
      imgSrc: 'testimonials/Abhishek-Ganjoo.png',
      dp: 'testimonials/Abhishek-Ganjoo.png',
      head:
        'Klub’s RBF instrument provides the best of both worlds to investors looking for better ROI than the mutual fund investments and lower risks than stock trading!',
      name: 'Abhishek Ganjoo',
      profile: 'Senior Consultant, mSE Solutions',
      url: null,
      isBrand: false
    },
    {
      id: '9',
      imgSrc: 'testimonials/Anish-Basu-Roy.png',
      dp: 'testimonials/Anish-Basu-Roy.png',
      head:
        `RBF is the best suited for young businesses with inventory cycles range from 3-6 months. Klub's offering is supported by a great team that understands the problems startups face.`,
      name: 'Anish Basu Roy',
      profile: 'Co-Founder & CEO, TagZ Foods',
      url: null,
      isBrand: true
    },
    {
      id: '10',
      imgSrc: 'testimonials/Shuchi-Pandya.png',
      dp: 'testimonials/Shuchi-Pandya.png',
      head:
        'Having worked with numerous NBFCs and financial partners, we found Klub’s RBF option for our marketing capital as a great fit!',
      name: 'Shuchi Pandya',
      profile: 'Founder & CEO, Pipa • Bella',
      url: null,
      isBrand: true
    }
  ],
  featuredSlides: [
    {
      id: '10',
      imgSrc: 'testimonials/Shuchi-Pandya.png',
      dp: 'testimonials/Shuchi-Pandya.png',
      head:
        `Having worked with numerous NBFC's and financial partners, we found Klub’s RBF option for our marketing capital as a great fit!`,
      name: 'Shuchi Pandya',
      profile: 'Founder & CEO, Pipa • Bella',
      url: null,
      isBrand: true
    },
    {
      id: '7',
      imgSrc: 'testimonials/Anish-Jain.png',
      head:
        'Klub is giving the opportunity to invest in new brands in a simple and secure manner. The team at Klub is proactive, and the platform is already flowing back returns on schedule!',
      name: 'Anish Jain',
      profile: 'Head of Sales, Google',
      url: null,
      dp: 'testimonials/Anish-Jain.png',
      isBrand: false
    },
    {
      id: '2',
      imgSrc: 'testimonials/tjori.png',
      head:
        'As founders, we really value our equity. Klub lets us raise revenue-linked capital without dilution and also helps eliminate the challenge of committed cash flow with traditional debt.',
      name: 'Mansi Gupta & Ankit Wadhwa',
      profile: 'Co-founders, Tjori',
      // url:
      //   'https://www.youtube-nocookie.com/embed/IfTb0Yz8tQE?showinfo=0&rel=0&autoplay=1&mute=0',
      dp: 'testimonials/mansi-gupta.png',
      isBrand: true
    },
    {
      id: '4',
      imgSrc: 'testimonials/tejasvi-video.png',
      head:
        'As an investment that provides good returns with my risk on capital reducing every month, I found Klub\'s RBF model to be very interesting.',
      name: 'Tejasvi Bhat',
      profile: 'Director, Ce-Chem Pharmaceuticals Pvt. Ltd.',
      // url:
      //   'https://www.youtube-nocookie.com/embed/4Ogr3BKBmmA?showinfo=0&rel=0&autoplay=1&mute=0',
      dp: 'testimonials/tejasvi-bhat.png',
      isBrand: false
    }
  ]
};
