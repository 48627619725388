/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import SlickSlider from 'react-slick';
import arrowLeft from '../../../static/home/svg/Arrowleft.svg';
import arrowRight from '../../../static/home/svg/Arrowright.svg';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={arrowRight} alt="right" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <img src={arrowLeft} alt="left" />
    </div>
  );
}

const defaultSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
};

const Slider = (props) => {
  const { settings, children, name } = props;
  const finalSettings = {
    ...defaultSettings,
    ...settings
  };
  return (
    <div className={`${name}-slider-container custom-slider-container`}>
      <SlickSlider {...finalSettings}>{children}</SlickSlider>
    </div>
  );
};

export default Slider;
